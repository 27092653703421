'use strict';

angular.module('webPartnerPortalApp')
  .directive('saveButton', function () {
    return {
      templateUrl: 'app/saveButton/saveButton.html',
      restrict: 'E',
      scope: {
        state: '='
      },
      controller: ['$scope', '$http', '$window', '$timeout', 'Upload', '$q', function ($scope, $http, $window, $timeout, Upload, $q) {
        function processContentType(state) {
          if (!state.plp_content_id) {
            state.content_type ='TEXT';
          }
          if (!state.plp_content_label_id) {
            state.plp_content_label_id = 3;
          }
          if (!state.plp_content && state.content_type === 'TEXT' ) {
            state.content_type = 'NONE';
          }
          if (state.content_type === 'IMAGE') {
            if(state.plp_content) {
              state.image_path = state.plp_content;
              state.plp_content = '';
            }
          }
          return state;
        }

        if (!$scope.initialState) {
          if (!$scope.state.is_visa_promotion) {
            $scope.state.is_visa_promotion = false;
          }
          if (typeof $scope.state.is_active !== 'boolean') {
            $scope.state.is_active = true;
          }
          $scope.state = processContentType($scope.state);
          $scope.initialState = angular.copy($scope.state);
        }
        $scope.imageuploaded = false;
        $scope.change = function () {
          if (!angular.equals($scope.state, $scope.initialState)){
            $scope.verified = false;
          }
          return !angular.equals($scope.state, $scope.initialState);
        };

        $scope.$watch('state.is_active', function (n, o) {
          if (n !== o) {
            var payload = $scope.initialState,
              method = !$scope.state.plp_content_id ? 'POST' : 'PUT';
            payload.is_active = n;
            if (payload.content_type === 'NONE' || !payload.plp_content && payload.content_type === 'TEXT') {
              payload.content_type = 'TEXT';
            }
            if (!payload.plp_content) {
              payload.plp_content = '';
            }
            if (payload.content_type === 'IMAGE') {
              payload.plp_content = payload.image_path;
            }
            $http({
              method: method,
              url: 'api/cobranding',
              data: payload
            }).then(function(){
              $scope.state = angular.copy($scope.initialState);
              $scope.state = processContentType($scope.state);
            }, function(err){
              console.log(err);
            });
          }
        });

        $scope.$watch('state.image_path', function(n) {
          if(typeof n === 'object') {
            $scope.state.new_img = true;
          }
        });

        function upload(state, initialState) {
          var deferred = $q.defer();
          if(state.content_type === 'IMAGE' && state.image_path !== initialState.image_path) {
            Upload.upload({
              url: 'api/cobranding/upload',
              data: {file: $scope.state.image_path}
            }).then(function (res) {
              $scope.state.image_path = res.data.body.substr(res.data.body.lastIndexOf('/') + 1, res.data.body.length);
              $scope.state.new_img = false;
              $scope.imageuploaded = true;
              deferred.resolve(res);
            }, function(){
              deferred.reject();
              $scope.imageuploaded = false;
            });
          } else {
            deferred.resolve();
          }

          $timeout(function() {
            $scope.imageuploaded = false;
          }, 3000);

          return deferred.promise;
        }

        $scope.save = function () {
          var method = !$scope.state.plp_content_id ? 'POST' : 'PUT',
          payload = angular.copy($scope.state);
          $scope.error = false;
          upload(payload, $scope.initialState)
            .then(function(res){
              if (res) {
                payload.plp_content = res.data.body;
              }
              if ($scope.state.content_type === 'NONE' || !$scope.state.plp_content && $scope.state.content_type === 'TEXT') {
                payload.content_type = 'TEXT';
                payload.plp_content = '';
              }
              if(payload.content_type === 'IMAGE' && payload.plp_content) {
                payload.plp_content = payload.plp_content.substr(payload.plp_content.lastIndexOf('/') + 1, payload.plp_content.length);
              }
              if($scope.state.content_type === 'IMAGE' && $scope.state.image_path !== $scope.initialState.image_path || $scope.state.content_type === 'IMAGE' && !$scope.state.image_path) {
                if (!payload.image_path && payload.image_path === '') {
                  $scope.error = 'Please select an image to upload or select another cobranding option.';
                  return;
                }
              }
              if($scope.state.content_type === 'IMAGE' && $scope.state.image_path !== ''){
                $scope.state.plp_content = $scope.state.image_path;
              }
                $http({
                method: method,
                url: 'api/cobranding',
                data: payload
              }).then(function(res){
                var result = res.data.body;
                if (result.content_type === 'TEXT') {
                  $scope.state.image_path = '';
                if (result.plp_content === '') {
                  $scope.state.content_type = 'NONE';
                }
                }
                if (method === 'PUT' && !result.plp_content) {
                  $scope.state.content_type = 'NONE';
                }
                $scope.state.plp_content_id = result.plp_content_id;
                $scope.initialState = angular.copy($scope.state);
              }, function(err){
                $scope.error = err.data.body.message;
              });
              }
            );
        };
      }]
    };
  })
  .directive('fileread', [function () {
    return {
      scope: {
        fileread: '='
      },
      link: function (scope, element) {
        element.bind('change', function (changeEvent) {
          scope.$apply(function () {
            scope.fileread = changeEvent.target.files[0];
          });
        });
      }
    };
  }]);
